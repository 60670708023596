.Projects {
  padding: 40px;
  text-align: center;
}

.Projects h2 {
  font-size: 28px;
  color: #282c34;
  margin-bottom: 20px;
}

.Projects p {
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 20px;
}

.GithubLink {
  display: inline-block;
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 20px; /* Increase font size */
  color: #fff;
  background-color: #333; /* GitHub dark color */
  text-decoration: none;
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
}

.GithubLink:hover {
  background-color: #555; /* Slightly lighter color on hover */
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}
