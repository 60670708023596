.Games {
  padding: 20px;
  text-align: center;
  padding-bottom: 200px;
}

.Games h1 {
  font-size: 35px;
  color: #333;
}

.game-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-item {
  margin: 10px 0;
}

.game-link {
  display: block;
  padding: 20px 30px;
  font-size: 30px;
  text-decoration: none;
  color: #3e607c;
  background: #f8f9fa;
  border: 2px solid #3e607c;
  border-radius: 8px;
  transition: background 0.3s, color 0.3s, transform 0.3s;
}

.game-link:hover {
  background: #3e607c;
  color: #fff;
  transform: scale(1.05);
}
