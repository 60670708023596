.Home {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 240px); /* Adjust height to consider header and footer height */
  overflow: hidden; /* Prevent content from overflowing */
}

.Content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  flex-wrap: wrap; /* Allow content to wrap on smaller screens */
}

.Box {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  min-width: 50%;
  overflow: hidden;
}

.ProfileImage {
  width: 100%;
  height: auto;
  max-width: 250px;
  max-height: 400px;
  object-fit: contain;
}

.Box p {
  font-size: 20px;
  line-height: 1.6;
  text-align: left;
  max-width: 400px;
  font-family: 'Arial', sans-serif;
  color: #ffffff;
  padding: 20px;
  background-color: #3e607c;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 800px) {
  .Content {
    flex-direction: column;
    align-items: center;
  }

  .Box {
    max-width: 100%;
    min-width: 100%;
  }

  .ProfileImage {
    max-width: 200px;
    max-height: 300px;
  }

  .Box p {
    max-width: 100%;
  }
}

@media (max-height: 700px) and (max-width: 800px) {
  .Home {
    height: auto;
    padding-top: 200px;
    padding-bottom: 400px;
  }

  .Content {
    flex-direction: column;
  }

  .ProfileImage {
    max-width: 200px;
    max-height: 300px;
  }

  .Box p {
    max-width: 100%;
  }
}
