.Contact {
  padding: 40px;
  text-align: center;
}

.Contact h2 {
  font-size: 28px;
  color: #282c34;
  margin-bottom: 20px;
}

.Contact p {
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 20px;
}

.LinkedInLink {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #0077B5; /* LinkedIn blue color */
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.LinkedInLink:hover {
  background-color: #005582; /* Slightly darker blue on hover */
}
