body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d1d4bba9;
}

.App {
  text-align: center;
}

.Header, .Footer {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.Home {
  padding: 20px;
}


